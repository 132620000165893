@import '../../config/index.css';

body {
  font-family: 'Rubik', serif !important;
}

.content {
  min-height: 100vh;
}

.login-container {
  margin-top: 10vh;
}

.login-page .card-login.card-white .card-header .card-title {
  color: black;
}

.login-page .card-login .card-header {
  padding: 0 0 10px;
}

.direction-ltr {
  direction: ltr !important;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background-image: none;
}

.login-page .card-login .card-header {
  text-align: center;
  overflow: visible;
}

.login-page .card-login .card-header img {
  position: static !important;
  margin-top: -62px;
}

.card.card-white .input-group-append .input-group-text {
  border-color: rgba(29, 37, 59, 0.2);
}

.login-page .card-login.card-white .input-group-append .input-group-text {
  border-color: rgba(29, 37, 59, 0.2);
  color: #1d253b;
}

.sidebar[data='green'],
.off-canvas-sidebar[data='green'] {
  background: var(--primary-color);
  background: liner-gradient-OFF;
}

.rtl .sidebar .nav p,
.rtl .bootstrap-navbar .nav p {
  font-size: 14px;
}

a {
  color: var(--primary-color);
}

.branded-link {
  color: var(--branded-color);
}

.copyright {
  float: none;
}

.button {
  background-color: var(--primary-color);
  background-image: none;
}

.input-file {
  background-color: rgb(240, 234, 234);
  text-align: center;
}

.label-secondary-image {
  font-weight: bold;
  cursor: pointer;
}

h4 {
  color: var(--primary-color);
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(2)
  > div
  > div
  > div.card-body
  > div
  > div.pagination-top
  > div
  > div.-center
  > span.-pageInfo
  > div
  > input[type='number'] {
  width: 60px;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div
  > div
  > div
  > div.card-body
  > div
  > div.pagination-top
  > div
  > div.-center
  > span.-pageInfo
  > div
  > input[type='number'] {
  width: 60px;
}

/* switch articles */
#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(4)
  > div.d-flex.justify-content-end.col-md-4
  > div
  > label::before {
  background-color: white;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(4)
  > div.d-flex.justify-content-end.col-md-4
  > div
  > label::after {
  background-color: var(--secondary-color);
}

/* switch recipes */
#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(3)
  > div.d-flex.justify-content-end.col-md-4
  > div
  > label::before {
  background-color: white;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(3)
  > div.d-flex.justify-content-end.col-md-4
  > div
  > label::after {
  background-color: var(--secondary-color);
}

/* switch plants */
#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(3)
  > div.d-flex.justify-content-end.col-md-4
  > div
  > label::before {
  background-color: white;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(3)
  > div.d-flex.justify-content-end.col-md-4
  > div
  > label::after {
  background-color: var(--secondary-color);
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(3)
  > div
  > div
  > div.card-body
  > div:nth-child(1)
  > div.pl-md-1.col-md-4
  > div
  > div
  > div:nth-child(1)
  > button {
  background: white;
  background-image: none;
  color: #1d253b;
  border: 1px solid rgba(29, 37, 59, 0.5);
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(3)
  > div
  > div
  > div.card-body
  > div:nth-child(1)
  > div.pl-md-1.col-md-4
  > div
  > div
  > div:nth-child(2)
  > button {
  background: white;
  background-image: none;
  color: #1d253b;
  border: 1px solid rgba(29, 37, 59, 0.5);
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(3)
  > div
  > div
  > div.card-body
  > form
  > div:nth-child(2)
  > div.pl-md-1.col-md-4
  > div
  > div
  > div:nth-child(1)
  > button {
  background: white;
  background-image: none;
  color: #1d253b;
  border: 1px solid rgba(29, 37, 59, 0.5);
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(3)
  > div
  > div
  > div.card-body
  > form
  > div:nth-child(2)
  > div.pl-md-1.col-md-4
  > div
  > div
  > div:nth-child(2)
  > button {
  background: white;
  background-image: none;
  color: #1d253b;
  border: 1px solid rgba(29, 37, 59, 0.5);
}

/* plant spots */
#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(2)
  > div
  > div
  > label::before {
  background-color: white;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div:nth-child(2)
  > div
  > div
  > label::after {
  background-color: var(--secondary-color);
}

/* table buttons */
#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div
  > div
  > div
  > div.card-body
  > div
  > div.pagination-top
  > div
  > div.-previous
  > button {
  background-image: none;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content.rtl
  > div
  > div
  > div
  > div.card-body
  > div
  > div.pagination-top
  > div
  > div.-next
  > button {
  background-image: none;
}

/* switch notification - article */

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(3)
  > div
  > div
  > div.card-body
  > div:nth-child(14)
  > div
  > div
  > label::before {
  background-color: white;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(3)
  > div
  > div
  > div.card-body
  > div:nth-child(14)
  > div
  > div
  > label::after {
  background-color: var(--secondary-color);
}

/* switch notification - recipe */

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(4)
  > div
  > div
  > div.card-body
  > div:nth-child(21)
  > div
  > div
  > label::before {
  background-color: white;
}

#root
  > div.wrapper
  > div.main-panel.ps.ps--active-y
  > div.content
  > div:nth-child(4)
  > div
  > div
  > div.card-body
  > div:nth-child(21)
  > div
  > div
  > label::after {
  background-color: var(--secondary-color);
}

.autosize-textarea {
  border-color: rgba(29, 37, 59, 0.5);
  color: #222a42;
  width: 100%;
  border-radius: 6px;
  padding: 10px 18px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  min-height: 60px;
}

.autosize-textarea:focus,
input:focus,
input[type]:focus,
.uneditable-input:focus {
  border-color: #ff3d62;
  outline: 0 none;
}

.actions-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.user-profile-link {
  color: var(--primary-color) !important;
  padding: 0px 24px;
  text-align: center;
}

.user-change-status {
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: white;
  padding: 5px;
  border: none;
}

.alert button.close::after {
  content: '\2715' !important;
}

.rdtPicker th.rdtNext:before {
  content: '\21e6';
}
.rdtPicker th.rdtPrev:before {
  content: '\21e8';
}
.rdtPicker .rdtTimeToggle:before {
  content: '\1f563';
}

.underline--magical {
  background-image: linear-gradient(120deg, #1d253b 0%, #1d253b 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.1em;
  background-position: 0 88%;
  padding: 10px 0px 10px 0px;
}

::placeholder {
  color: #adb5bd;
}

.sidebar[data='green']:before,
.off-canvas-sidebar[data='green']:before {
  border-bottom-color: var(--primary-color);
}

.btn.dropdown-toggle:after,
.navbar .navbar-nav > a.btn.dropdown-toggle:after {
  margin-left: 0px !important;
}

.react-datepicker__input-container input {
  padding: 10px 18px;
  text-align: center;
  font: 400 13.3333px Arial;
  border: 1px solid grey;
  border-radius: 0.4285rem;
}
#root > div.rna-container > div > div > div > div > span:nth-child(3) {
  text-align: left;
}

.date-picker-input-lanci {
  width: 200px;
}
.checkbox-label {
  margin-right: 25px;
}

.payment-method-select-label {
  font-size: 16px !important;
  font-weight: bold;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}
